import React from "react";
import Sidebar from "../components/sidebar/sidebar";
import Posts from "../components/posts/posts";
import Pagination from "../components/pagination/pagination";
import * as styles from "./posts-template.module.scss";
import { graphql } from "gatsby";

const TagsPostsTemplate = (props) => {
  return (
    <React.Fragment>
      <div className={"row " + styles.wrapper}>
        <div className={"col-lg-3 col-xs-12 " + styles.side}>
          <Sidebar />
        </div>
        <div className="col-lg-9 col-xs-12">
          <Posts posts={props.data.allMarkdownRemark.nodes} />
          <Pagination
            nextPage={props.pageContext.nextPagePath}
            prePage={props.pageContext.previousPagePath}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export const query = graphql`
  query ($skip: Int!, $limit: Int!, $tag: String!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        isFuture: { eq: false }
        isDraft: { eq: false }
        tags: { in: [$tag] }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          title
          tags
          date
          categories
          author
          coverImage {
            childImageSharp {
              gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
            }
          }
        }
        title
        html
        isFuture
        url
        isDraft
        tags
        id
        excerpt(format: PLAIN, truncate: true)
      }
    }
  }
`;

export default TagsPostsTemplate;
