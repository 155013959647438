import React from "react";
import * as styles from "./sidebar.module.scss";
import { Link, graphql, useStaticQuery } from "gatsby";
import logoImg from "../../images/logo.png";
import ukFoodImg from "../../images/uk-food.jpg";
import ukGuideImg from "../../images/uk-guide.jpg";
import ukTravelImg from "../../images/uk-travel.jpg";
import ukBookingImg from "../../images/uk-booking.jpg";
import travelImg from "../../images/travel.jpg";
import { GatsbyImage } from "gatsby-plugin-image"

const Sidebar = () => {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(
          sort: { fields: frontmatter___date, order: DESC }
          filter: {
            isFuture: { eq: false }
            isDraft: { eq: false }
            pinned: { eq: true }
          }
        ) {
          nodes {
            title
            url
            frontmatter {
              date
              coverImage {
                childImageSharp {
                  gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
                }
              }
            }
          }
        }
      }
    `
  );
  let posts = data.allMarkdownRemark.nodes;
  return (
    <React.Fragment>
      <div className={styles.sidebar}>
        <div className={styles.top}>
          <Link className={styles.logo} to="/">
            <img src={logoImg} alt="吃什么" />
          </Link>
          <h1>吃什么</h1>
          <h1 className={styles.subtitle}>EATSOMEMORE</h1>
        </div>
        <div className={styles.categories}>
          <article className={styles.category}>
            <Link className={styles.thumbnail} to="/topics/uk-travel">
              <img src={ukTravelImg} alt="英伦四季高光" />
            </Link>
            <div className={styles.header}>
              <h3>
                <Link to="/topics/uk-travel">英伦四季高光</Link>
              </h3>
              <p>
                英国只有国会塔桥伦敦眼炸鱼薯条大本钟吗？一起来看看你知道或者不知道的英伦四季有什么吧！
              </p>
            </div>
          </article>

          <article className={styles.category}>
            <Link className={styles.thumbnail} to="/topics/uk-food">
              <img src={ukFoodImg} alt="英国吃什么" />
            </Link>
            <div className={styles.header}>
              <h3>
                <Link to="/topics/uk-food">英国吃什么</Link>
              </h3>
              <p>
                常常听人说英国无美食，也看过炸鱼被讽刺，土豆被内涵，所以希望能够通过《英国吃什么》专题为英国的食物正名，跟大家分享在英国旅行的时候有哪些值得体验的地道美食。每一片介绍的标题当中，［推荐］是指如果在附近玩耍的话可以去尝试的好吃的餐厅，［精选］是指在［推荐］餐厅当中都比较优秀的代表，而［必去］则是指即使不在附近也值得特地去吃的餐厅。
              </p>
            </div>
          </article>

          <article className={styles.category}>
            <Link className={styles.thumbnail} to="/topics/uk-guide">
              <img src={ukGuideImg} alt="英国玩什么" />
            </Link>
            <div className={styles.header}>
              <h3>
                <Link to="/topics/uk-guide">英国玩什么</Link>
              </h3>
              <p>
                来英国不能错过的迷人风景大揭秘。每一片介绍的标题当中，［推荐］是指如果在附近的话可以一去的景点，［精选］是指在［推荐］景点当中都比较优秀的代表，而［必去］则是指即使不在附近也值得特地去看一看的景点。
              </p>
            </div>
          </article>

          <article className={styles.category}>
            <Link className={styles.thumbnail} to="/topics/uk-booking">
              <img src={ukBookingImg} alt="英国很贵么" />
            </Link>
            <div className={styles.header}>
              <h3>
                <Link to="/topics/uk-booking">英国很贵么</Link>
              </h3>
              <p>英国消费高？我们想要选对的，不选贵的！</p>
            </div>
          </article>

          <article className={styles.category}>
            <Link className={styles.thumbnail} to="/topics/travel">
              <img src={travelImg} alt="英国之外呢" />
            </Link>
            <div className={styles.header}>
              <h3>
                <Link to="/topics/travel">英国之外呢</Link>
              </h3>
              <p>世界那么大，我们当然不能止步英国啦！</p>
            </div>
          </article>
        </div>
        <div className={styles.topPosts}>
          {posts.map((post, index) => {
            return (
              <article className={styles.topPost} key={index}>
                <Link className={styles.thumbnail} to={post.url}>
                  <GatsbyImage
                    image={
                      post.frontmatter.coverImage.childImageSharp.gatsbyImageData
                    }
                    alt={post.title}
                  />
                </Link>
                <div className={styles.desc}>
                  <Link className={styles.title} to={post.url}>
                    <h2>{post.title}</h2>
                  </Link>
                  <p>{post.frontmatter.date}</p>
                </div>
              </article>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
