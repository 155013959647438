import React from "react";
import * as styles from "./posts.module.scss";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

const Posts = (props) => {
  let posts = props.posts;

  return (
    <React.Fragment>
      <div className={styles.posts}>
        {posts.map((post, index) => {
          return (
            <div className={styles.post} key={index}>
              <div className={styles.header}>
                <h2 className={styles.title}>
                  <Link to={post.url}>{post.title}</Link>
                </h2>
                <p className={styles.date}>{post.frontmatter.date}</p>
              </div>
              <div className={styles.featureImage}>
                <GatsbyImage
                  image={
                    post.frontmatter.coverImage.childImageSharp.gatsbyImageData
                  }
                  alt={post.title}
                />
              </div>
              <p>{post.excerpt}</p>
              <div className={styles.button}>
                <Link to={post.url}>阅读全文</Link>
              </div>
              <ul className={styles.metas}>
                <li>
                  分类:
                  <span>
                    <Link to={"/categories/" + post.frontmatter.categories}>
                      {post.frontmatter.categories}
                    </Link>
                  </span>
                </li>
                <li>
                  标签:
                  {post.tags.map((tag, index) => {
                    return (
                      <span key={index}>
                        <Link to={"/tags/" + tag}>{tag}</Link>
                      </span>
                    );
                  })}
                </li>
              </ul>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default Posts;
